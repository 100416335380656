<template>
  <div>
    <!-- Footer -->
    <footer class="text-center text-white pt-lg-5">
      <div class="container">
        <div class="row flex-row justify-content-around justify-content-lg-between">
          <div class="d-flex flex-column text-lg-left text-center pb-2">
            <a href="/program">{{ "Navigation.program" | staticTranslation }}</a>
            <small><a href="/program">{{ "Navigation.lineup" | staticTranslation }}</a></small>
            <!--            <small><a href="https://goout.net/cs/maraton-hudby-brno-2023/szvlukv/">{{ "Navigation.entrance.tickets" | staticTranslation }}</a></small>-->
            <!--            <small><a href="/interprets">{{ "Navigation.interprets" | staticTranslation }}</a></small>-->
            <!--            <small><a href="/localities">{{ "Navigation.localities" | staticTranslation }}</a></small>-->
            <small><a href="/busking">{{ "Navigation.busking" | staticTranslation }}</a></small>
            <!--            <small><a href="/na-prknech-dlazbe-trave">{{ "Navigation.woodAndGrass" | staticTranslation }}</a></small>-->
            <small><a href="/directors">{{ "Navigation.directors" | staticTranslation }}</a></small>
          </div>
          <div v-if="isActiveCs" class="d-flex flex-column text-lg-left pb-2 text-center">
            <a href="/news">{{ "Navigation.news" | staticTranslation }}</a>
          </div>
          <div class="d-flex flex-column text-lg-center text-center">
            <a href="/media">{{ "Navigation.media" | staticTranslation }}</a>
          </div>
          <div class="d-flex flex-column text-lg-center text-center">
            <a href="/contact">{{ "Navigation.contact" | staticTranslation }}</a>
          </div>
          <div class="d-flex flex-column text-lg-left text-center pb-2">
            <a>{{ "Navigation.about" | staticTranslation }}</a>
            <small v-if="isActiveCs"><a href="/about">{{ "Navigation.aboutFestival" | staticTranslation }}</a></small>
            <small><a href="/sponsors">{{ "Navigation.sponsors" | staticTranslation }}</a></small>
            <small><a href="/archiv">{{ "Navigation.archives" | staticTranslation }}</a></small>
            <small><a
                href="https://www.gotobrno.cz/ochutnejte/">{{ "Navigation.tasteBrno" | staticTranslation }}</a></small>
          </div>
<!--          <div class="d-flex flex-column text-lg-center text-center">-->
<!--            <a href="/foodStreet">{{ "Navigation.streetFood" | staticTranslation }}</a>-->
<!--          </div>-->

          <div class="d-flex flex-column text-lg-center text-center pb-2">
            <a href="/gallery">{{ "Navigation.gallery" | staticTranslation }}</a>
          </div>

        </div>
      </div>
      <!-- Copyright -->
      <div class="text-center copyright" style="background-color: rgba(0, 0, 0, 0.2);">
       <small>© 2021 - 2023 Copyright:</small>
        <a class="text-white link-small" href="https://dokamar.cz">Dokamar s.r.o</a>
      </div>

      <!-- Copyright -->
    </footer>
    <!-- Footer -->

  </div>
</template>

<script>

export default {
  name: "maratonFooter",
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }
}
</script>

<style scoped>
a {
  color: white;
  padding-top: 5px;
  display: block;
  font-size: 1.4em;
  font-family: "Transcript Mono";
}

small {
  font-size: .6em;
}

@media screen and (max-width:500px) {
  .d-flex {
    width: 50%;
  }
}
footer {
  z-index: 1000;
  background-color: #152326;
}
input {
  font-family: "Transcript Mono";
}
.copyright {
  padding: 6px 6px 12px 6px;
  margin-top: 4em;
}
.link-small {
  font-size: 1em;
}

</style>
