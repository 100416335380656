<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <!-- Brand -->
    <div class="col-md-12 col-lg-1 col-xl-2">
      <div class="row">
        <div class="col-8 col-sm-6 col-lg-10 col-xl-10">
          <a class="navbar-brand pl-lg-4 text-left" href="/"><img class="img-fluid img-width"
                                                                  src="@/assets/MHB-logo-černé.png" alt="Logo"></a>
        </div>
        <div class="col-4 col-sm-6 col-lg-12 col-xl-2 text-right">
          <button class="navbar-toggler mt-2" type="button" data-toggle="collapse" data-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon custom-toggler"></span>
          </button>
        </div>
      </div>
    </div>
    <!-- Links -->
    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="col-xl-9 offset-0 offset-xl-0">
        <div class="dropdown">
          <div>
            <button class="dropbtn">{{ "Navigation.program" | staticTranslation }} ▾</button>
          </div>
          <div class="dropdown-content">
            <a href="/program">{{ "Navigation.lineup" | staticTranslation }}</a>
            <a href="/interprets">{{ "Navigation.interprets" | staticTranslation }}</a>
            <a href="/localities">{{ "Navigation.localities" | staticTranslation }}</a>
            <a href="/busking">{{ "Navigation.busking" | staticTranslation }}</a>
            <!--            <a href="/na-prknech-dlazbe-trave">{{ "Navigation.woodAndGrass" | staticTranslation }}</a>-->
            <a href="/directors">{{ "Navigation.directors" | staticTranslation }}</a>
          </div>
        </div>
        <div class="dropdown">
          <router-link to="/news">
            <button class="dropbtn">{{ "Navigation.news" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown">
          <div>
            <button class="dropbtn">{{ "Navigation.about" | staticTranslation }} ▾</button>
          </div>
          <div class="dropdown-content">
<!--            <a v-if="isActiveCs" href="/about">{{ "Navigation.aboutFestival" | staticTranslation }}</a>-->
            <a href="/about">{{ "Navigation.aboutFestival" | staticTranslation }}</a>
            <a href="/sponsors">{{ "Navigation.sponsors" | staticTranslation }}</a>
            <a href="/archiv">{{ "Navigation.archives" | staticTranslation }}</a>
            <a href="https://www.gotobrno.cz/ochutnejte/">{{ "Navigation.tasteBrno" | staticTranslation }}</a>
          </div>
        </div>
<!--        <a class="style-href-menu" href="https://goout.net/cs/maraton-hudby-brno-2023/szvlukv/">{{ "Navigation.entrance.tickets" | staticTranslation }}</a>-->


 <div class="dropdown">
          <router-link to="/foodStreet">
            <button class="dropbtn">{{ "Navigation.streetFood" | staticTranslation }}</button>
          </router-link>
        </div> 
        <div class="dropdown">
          <router-link to="/gallery">
            <button class="dropbtn">{{ "Navigation.gallery" | staticTranslation }}</button>
          </router-link>
        </div>
        <div class="dropdown">
          <router-link to="/media">
            <button class="dropbtn">{{ "Navigation.media" | staticTranslation }}</button>
          </router-link>
        </div>

        <div class="dropdown">
          <router-link to="/contact">
            <button class="dropbtn">{{ "Navigation.contact" | staticTranslation }}</button>
          </router-link>
        </div>
      </div>
      <div class="col-lg-3 col-xl-3 text-right">
        <a class="socialMedia" href="https://www.facebook.com/maratonhudby/"><img class="animation"
                                                                                  src="@/assets/LogoFB.png" width="9%"
                                                                                  alt="Facebook"></a>
        <a class="socialMedia" href="https://www.instagram.com/maraton_hudby_brno/"><img class="animation"
                                                                                         src="@/assets/LogoIG.png"
                                                                                         width="9%"
                                                                                         alt="Instagram"></a>
        <a class="socialMedia" href="https://www.youtube.com/channel/UCqqmZYehiR0BodY4JhdZ3dg"><img class="animation"
                                                                                                    src="@/assets/LogoYT.png"
                                                                                                    width="9%"
                                                                                                    alt="Youtube"></a>

        <a class="socialMedia" onclick="return false;" v-on:click="setCs" v-bind:class="{ active: isActiveCs}" ><img class="animation"
                                                                                                                     src="@/assets/LogoCZ.png"
                                                                                                                     width="9%"
                                                                                                                     alt="Youtube"></a>
        <a class="socialMedia" onclick="return false;" v-on:click="setEn" v-bind:class="{ active: isActiveEn}" ><img class="animation"
                                                                                                                     src="@/assets/LogoEN.png"
                                                                                                                     width="9%"
                                                                                                                     alt="Youtube"></a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  methods: {
    setCs: function () {
      localStorage.setItem("lang", "cs")
      location.reload()
      // TODO remove when translations will be finished
      this.$router.push("/")
    },
    setEn: function () {
      localStorage.setItem("lang", "en")
      location.reload()
      // TODO remove when translations will be finished
      this.$router.push("/")
    }
  },
  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }
}
</script>

<style scoped>
nav {
  background-color: #eae7d6;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.navbar-toggler {
  background-color: #152326;
}

.custom-toggler {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(243,186,83, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(243,186,83, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

}

.custom-toggler .navbar-toggler {
  border-color: #f3ba53;
}

.dropbtn {
  color: black;
  padding: 12px;
  font-size: 15px;
  border: none;
  background-color: #eae7d6;
  font-family: "Transcript Mono";
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #eae7d6;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: "Transcript Mono";
}
.style-href-menu {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: inline-block;
  font-family: "Transcript Mono";
}

.dropdown-content a:hover {
  background-color: #eae7d6;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #eae7d6;
}

.socialMedia {
  padding: 5px;
  cursor: pointer;
}

.animation:hover {
  animation-name: social;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}

@keyframes social {
  0% {
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0px);
  }
}

.img-width {
  width: 100%;
}
/*responsive*/
@media screen and (max-width: 575px) {
  .img-width {
    width: 50%;
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .img-width {
    width: 32%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-brand {
    display: none;
  }
}
@media screen and (min-width: 1400px) {
  .dropbtn {
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
    background-color: #eae7d6;
    font-family: "Transcript Mono";
  }

}
@media screen and (min-width: 992px) and (max-width: 1199px){
  .navbar-expand-lg {
    flex-flow: wrap;
  }
}

@media screen and (min-width: 992px) and (max-width: 1054px) {
  .dropbtn {
    color: black;
    padding: 8px;
    font-size: 14px;
    border: none;
    background-color: #eae7d6;
    font-family: "Transcript Mono";
  }
}
@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .dropbtn {
    color: black;
    padding: 12px;
    font-size: 14px;
    border: none;
    background-color: #eae7d6;
    font-family: "Transcript Mono";
  }
}


.btn-dark {
  background-color: #5a6169;
  border-color: #5a6169;
}

.btn-dark:hover {
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-sm {
  margin: 3px;
}
</style>
